const compState = {
  namespaced: true,
  state: {
    screenSize: '',
    isShowModal: false,
    actIMGIndex: 0,
    actIMGLength: 0,
    actIMGList: []
  },
  getters: {
    getIsShowModal: state => {
      return state.isShowModal
    },
    getActIMGIndex: state => {
      return state.actIMGIndex
    },
    getActIMGLength: state => {
      return state.actIMGLength
    },
    getActIMGList: state => {
      return state.actIMGList
    },
    getScreenSize: state => {
      return state.screenSize
    }
  },
  mutations: {
    setIsShowModal: (state, payload) => {
      state.isShowModal = payload
    },
    setActIMGIndex: (state, payload) => {
      state.actIMGIndex = payload
    },
    setActIMGLength: (state, payload) => {
      state.actIMGLength = payload
    },
    setActIMGList: (state, payload) => {
      state.actIMGList = payload
    },
    setScreenSize (state) {
      const width = window.innerWidth
      const height = window.innerHeight
      const aspectRatio = width / height

      if (aspectRatio < 1.78) {
        state.screenSize = 'mobile'
      } else {
        state.screenSize = 'desktop'
      }
    }

  },
  actions: {
    openActivityModal: ({ commit }, payload) => {
      commit('setActIMGIndex', payload.actIMGIndex)
      commit('setIsShowModal', payload.isShowModal)
      commit('setActIMGLength', payload.actIMGLength)
    },
    setScreenSize (context) {
      context.commit('setScreenSize')
    }
  }
}

export default compState
