<template>
<swiper
  v-if="!compState.isMobile"
  class="w-[70%] relative pt-10"
  :loop="true"
  :slides-per-view="1"
  :space-between="0"
  :allow-touch-move="false"
  @slideChange="onSlideChange"
  @init="onSlideInit"
  @swiper="onSwiper"
>
    <swiper-slide v-for="(slide, slideIndex) in chunkedIMGList" :key="slideIndex" class="w-[60rem]">
      <div class="grid grid-cols-3 gap-12">
        <div v-for="(item, index) in slide" :key="index">
          <img
            @click="() => onIMGClick(( slideIndex * 6 + index))"
            class="w-[100%] h-[70%] object-cover hover:cursor-pointer"
            :src="item[0]"
            alt=""
          />
          <div>
            <p class="mt-4 font-medium text-lg text-trueeye-red">
              {{ $t('activity.actName' + (actIMGList.length  - (slideIndex * 6 + index + 1))) }}
            </p>
            <p class="font-light text-base text-black">
              {{ $t('activity.actDate' + (actIMGList.length  - (slideIndex * 6 + index + 1))) }}
            </p>
          </div>
        </div>
      </div>
    </swiper-slide>

  <div class="absolute right-0 bottom-0 flex gap-2">
    <div @click="onBack" class="w-[30px] h-[30px] z-10 hover:cursor-pointer opacity-75">
      <img class="w-full h-full" :src="backIcon" alt="" />
    </div>
    <span class="font-medium text-black text-2xl">
      {{ `${compState.newsPageIndex + 1}/${compState.newsPageLength}` }}
    </span>
    <div @click="onNext" class="w-[30px] h-[30px] z-10 hover:cursor-pointer opacity-75">
      <img class="w-full h-full" :src="nextIcon" alt="" />
    </div>
  </div>
</swiper>

  <div v-if="compState.isMobile" class="gap-12 w-[80%] mobile:w-full mobile:flex mobile:gap-x-6 mobile:gap-y-8 mobile:justify-center mobile:flex-wrap">
    <div v-for="(item, index) in actIMGList" :key="index" class="">
      <img
        @click="() => onIMGClick(index)"
        class="w-[100%] h-[70%] mobile:h-[96px] mobile:w-[168px] object-cover hover:cursor-pointer"
        :src=item[0]
        alt=""
      />
      <div>
        <p class="mobile:mt-5 mobile:font-light  mobile:w-[168px] mobile:text-sm mobile:text-[#CC0006]">{{ $t('activity.actDate'+[actIMGList.length - index -1]) }}</p>
        <p class="mobile:mt-2 mobile:font-light  mobile:w-[168px] mobile:text-sm mobile:text-black">{{ $t('activity.actName'+[actIMGList.length - index -1]) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useMediaQuery } from '@vueuse/core'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

import backIcon from '../assets/TrueEye/icon/arrow 7.png'
import nextIcon from '../assets/TrueEye/icon/arrow 6.png'

import actIMGsmt0 from '../assets/TrueEye/Activity/Samitivej_2.jpg'
import actIMGsmt1 from '../assets/TrueEye/Activity/Samitivej_1.jpg'
import actIMGsmt2 from '../assets/TrueEye/Activity/Samitivej_3.jpg'
import actIMGenp0 from '../assets/TrueEye/Activity/ENPY_1.jpg'
import actIMGenp1 from '../assets/TrueEye/Activity/ENPY_2.jpg'
import actIMGenp2 from '../assets/TrueEye/Activity/ENPY_3.jpg'
import actIMGdhf0 from '../assets/TrueEye/Activity/Digital_health_forum_1.jpg'
import actIMGdhf1 from '../assets/TrueEye/Activity/Digital_health_forum_2.jpg'
import actIMGdhf2 from '../assets/TrueEye/Activity/Digital_health_forum_3.jpg'
import actIMGpcs0 from '../assets/TrueEye/Activity/PCSHS_1.jpg'
import actIMGpcs1 from '../assets/TrueEye/Activity/PCSHS_2.jpg'
import actIMGpcs2 from '../assets/TrueEye/Activity/PCSHS_3.jpg'
import actIMGpbs0 from '../assets/TrueEye/Activity/Thai_PBS_1.jpg'
import actIMGpbs1 from '../assets/TrueEye/Activity/Thai_PBS_2.jpg'
import actIMGpbs2 from '../assets/TrueEye/Activity/Thai_PBS_3.jpg'
import actIMGsyn0 from '../assets/TrueEye/Activity/Synbio_consortium_1.jpg'
import actIMGsyn1 from '../assets/TrueEye/Activity/Synbio_consortium_2.jpg'
import actIMGsyn2 from '../assets/TrueEye/Activity/Synbio_consortium_3.jpg'
import actIMGoist0 from '../assets/TrueEye/Activity/OIST_1.jpg'
import actIMGoist1 from '../assets/TrueEye/Activity/OIST_2.jpg'
import actIMGoist2 from '../assets/TrueEye/Activity/OIST_3.jpg'
import actIMGict0 from '../assets/TrueEye/Activity/ICT_1.jpg'
import actIMGict1 from '../assets/TrueEye/Activity/ICT_2.jpg'
import actIMGict2 from '../assets/TrueEye/Activity/ICT_3.jpg'
import actIMGictReward0 from '../assets/TrueEye/Activity/ICT_reward_1.jpg'
import actIMGictReward1 from '../assets/TrueEye/Activity/ICT_reward_2.jpg'
import actIMGictReward2 from '../assets/TrueEye/Activity/ICT_reward_3.jpg'
import actIMGspSanjorn0 from '../assets/TrueEye/Activity/SP_Sanjorn_1.jpg'
import actIMGspSanjorn1 from '../assets/TrueEye/Activity/SP_Sanjorn_2.jpg'
import actIMGspSanjorn2 from '../assets/TrueEye/Activity/SP_Sanjorn_3.jpg'
import actIMGnkt0 from '../assets/TrueEye/Activity/Nakhonthai_hospital_1.jpg'
import actIMGnkt1 from '../assets/TrueEye/Activity/Nakhonthai_hospital_2.jpg'
import actIMGnkt2 from '../assets/TrueEye/Activity/Nakhonthai_hospital_3.jpg'

const store = useStore()

const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)'),
  swiper: {},
  newsPageIndex: 1,
  newsPageLength: 1

})

const onSlideInit = (swiper) => {
  compState.newsPageLength = swiper.slides.length
  compState.newsPageIndex = swiper.realIndex
}
const onSlideChange = (swiper) => {
  compState.newsPageIndex = swiper.realIndex
}
const onSwiper = (swiper) => {
  compState.swiper = swiper
}
const onNext = () => {
  compState.swiper.slideNext()
}
const onBack = () => {
  compState.swiper.slidePrev()
}

const actIMGList = [
  [actIMGictReward0, actIMGictReward1, actIMGictReward2],
  [actIMGict0, actIMGict1, actIMGict2],
  [actIMGspSanjorn0, actIMGspSanjorn1, actIMGspSanjorn2],
  [actIMGnkt0, actIMGnkt1, actIMGnkt2],
  [actIMGoist0, actIMGoist1, actIMGoist2],
  [actIMGpcs0, actIMGpcs1, actIMGpcs2],
  [actIMGsyn0, actIMGsyn1, actIMGsyn2],
  [actIMGenp0, actIMGenp1, actIMGenp2],
  [actIMGdhf0, actIMGdhf1, actIMGdhf2],
  [actIMGpbs0, actIMGpbs1, actIMGpbs2],
  [actIMGsmt0, actIMGsmt1, actIMGsmt2]

]

onMounted(() => {
  store.commit('compState/setActIMGList', actIMGList)
})

const chunkedIMGList = computed(() => {
  const result = []
  const size = 6
  for (let i = 0; i < actIMGList.length; i += size) {
    result.push(actIMGList.slice(i, i + size))
  }
  console.log(result)
  return result
})

const onIMGClick = (index) => {
  store.dispatch('compState/openActivityModal', {
    isShowModal: true,
    actIMGIndex: index,
    actIMGLength: actIMGList.length
  })
  document.body.classList.add('modal-open')
  console.log(store.getters['compState/getIsShowModal'], store.getters['compState/getActIMGIndex'])
  console.log(index)
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
body.modal-open {
  overflow: hidden;
}
</style>
