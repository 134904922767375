<template>
<swiper
    v-if="!compState.isMobile"
    class="w-[70%] relative pt-10"
    :loop="true"
    :slides-per-view="1"
    :space-between="0"
    :allow-touch-move="false"
    @slideChange="onSlideChange"
    @init="onSlideInit"
    @swiper="onSwiper"
>
    <template v-for="(data, i) in newsDataComputed.desktopData" :key="i">
        <swiper-slide class="w-[60rem]">
            <template v-for="(subData, j) in data" :key="j">
                <div :class="{'mt-[3rem]': j === 1}" class="w-full h-[18rem] flex items-center justify-center">
                    <div class="w-[45%] h-full">
                        <a :href="subData.newsLink" target="_blank"><img class="h-[214px] w-[388px] hover:cursor-pointer" :src="subData.newsImage" alt=""></a>
                    </div>
                    <div class="w-1/2 h-full">
                        <div class="w-full h-full -mt-2">
                            <a :href="subData.newsLink" target="_blank"><span class=" font-medium text-2xl text-trueeye-red hover:cursor-pointer">{{ $t(subData.newsHeader) }}</span></a>
                            <p class=" mt-4 font-light text-lg text-black">{{ $t(subData.newsDate) }}</p>
                            <p class="mt-2 font-light text-lg text-black">{{ $t(subData.newsDetail) }}</p>
                            <p class="mt-4 font-light text-lg flex items-center text-black">{{ $t('news.newsRef') }}
                                <template v-for="(icon, k) in subData.icons" :key="k">
                                    <img class="h-[36px] w-[36px] mx-2 object-cover" :src="icon" alt="">
                                </template>
                            </p>
                        </div>
                    </div>
                </div>
            </template>
    </swiper-slide>
    </template>
    <!-- ----------------- -->
    <div class="absolute right-0 bottom-0 flex gap-2">
        <div @click="onBack" class=" w-[30px] h-[30px] z-10 hover:cursor-pointer opacity-75">
            <img class="w-full h-full" :src="backIcon" alt="">
        </div>
        <span class="font-medium text-black text-2xl"> {{ `${compState.newsPageIndex+1 }/${compState.newsPageLength}` }}</span>
        <div @click="onNext" class=" w-[30px] h-[30px] z-10 hover:cursor-pointer opacity-75">
            <img class="w-full h-full" :src="nextIcon" alt="">
        </div>
    </div>
</swiper>
<div v-if="compState.isMobile" class="mobile:w-full mobile:flex mobile:gap-x-6 mobile:gap-y-8 mobile:justify-center mobile:flex-wrap">
    <template v-for="(data, i) in newsDataComputed.mobileData" :key="i">
        <div class="mobile:w-[168px]">
            <a :href="data.newsLink" target="_blank"><img  class="mobile:h-[96px] mobile:w-[168px] hover:cursor-pointer" :src="data.newsImage" alt=""></a>
            <p class=" mobile:mt-5 mobile:font-light mobile:text-sm mobile:text-[#CC0006]">{{ $t(data.newsDate) }}</p>
            <a :href="data.newsLink" target="_blank"><p class="mobile:mt-2 mobile:font-light mobile:text-sm mobile:text-black">{{ $t(data.newsHeader) }}</p></a>
        </div>
    </template>
</div>
</template>

<script setup>
import { computed, reactive, onMounted } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { useMediaQuery } from '@vueuse/core'

import backIcon from '../assets/TrueEye/icon/arrow 7.png'
import nextIcon from '../assets/TrueEye/icon/arrow 6.png'

import newsImg10 from '../assets/TrueEye/News/OIST.png'
import newsImg8 from '../assets/TrueEye/News/News_Tpbs_2_resize.jpg'
import newsImg7 from '../assets/TrueEye/News/News_Chula.png'
import newsImg6 from '../assets/TrueEye/News/News_BT_resize.jpg'
import newsImg5 from '../assets/TrueEye/News/News_PM.png'
import newsImg4 from '../assets/TrueEye/News/News_TPBS_1_resize.jpg'
import newsImg3 from '../assets/TrueEye/News/Techsauce.png'
import newsImg2 from '../assets/TrueEye/News/Bangkokbiz.png'
import newsImg1 from '../assets/TrueEye/News/News_UTC.png'

import newsIconOIST from '../assets/TrueEye/LogoNews/OIST_logo.png'
import newsIconBT from '../assets/TrueEye/LogoNews/Beartai.jpeg'
import newsIconBiz from '../assets/TrueEye/LogoNews/BKKBiz.png'
import newsIconCH7 from '../assets/TrueEye/LogoNews/CH7HD.jpeg'
import newsIconCU from '../assets/TrueEye/LogoNews/Chula.jpeg'
import newsIconUTC from '../assets/TrueEye/LogoNews/CU_UTC.png'
import newsIconMGR from '../assets/TrueEye/LogoNews/MGRonline.jpeg'
import newsIconPPTV from '../assets/TrueEye/LogoNews/PPTV.jpeg'
import newsIconTS from '../assets/TrueEye/LogoNews/Techsauce.png'
import newsIconTD from '../assets/TrueEye/LogoNews/TeroDigital.jpeg'
import newsIconTE from '../assets/TrueEye/LogoNews/TeroEntertainment.png'
import newsIconTNA from '../assets/TrueEye/LogoNews/ThaiNewsAgency.png'
import newsIconPBS from '../assets/TrueEye/LogoNews/ThaiPBS.png'
import newsIconTP from '../assets/TrueEye/LogoNews/Thaipost.jpeg'
import newsIconTR from '../assets/TrueEye/LogoNews/Thairath.png'

const newsDataList = [
  {
    newsHeader: 'news.newsHeader10',
    newsDate: 'news.newsDate10',
    newsDetail: 'news.newsDetail10',
    newsImage: newsImg10,
    newsLink: 'https://www.youtube.com/watch?v=-3Kf9VfB83w&t=2s',
    icons: [newsIconOIST]
  },
  {
    newsHeader: 'news.newsHeader8',
    newsDate: 'news.newsDate8',
    newsDetail: 'news.newsDetail8',
    newsImage: newsImg8,
    newsLink: 'https://www.thaipost.net/news-update/297894/',
    icons: [newsIconTP]
  },
  {
    newsHeader: 'news.newsHeader7',
    newsDate: 'news.newsDate7',
    newsDetail: 'news.newsDetail7',
    newsImage: newsImg7,
    newsLink: 'https://www.tiktok.com/@chulalongkornuniversity/video/7205438633887714562',
    icons: [newsIconCU]
  },
  {
    newsHeader: 'news.newsHeader6',
    newsDate: 'news.newsDate6',
    newsDetail: 'news.newsDetail6',
    newsImage: newsImg6,
    newsLink: 'https://www.youtube.com/watch?v=DFW1I_SVJWY',
    icons: [newsIconBT, newsIconCH7, newsIconTD, newsIconTE]
  },
  {
    newsHeader: 'news.newsHeader5',
    newsDate: 'news.newsDate5',
    newsDetail: 'news.newsDetail5',
    newsImage: newsImg5,
    newsLink: 'https://www.thairath.co.th/news/politic/2563593',
    icons: [newsIconTR, newsIconPPTV, newsIconMGR, newsIconTNA]
  },
  {
    newsHeader: 'news.newsHeader4',
    newsDate: 'news.newsDate4',
    newsDetail: 'news.newsDetail4',
    newsImage: newsImg4,
    newsLink: 'https://techsauce.co/pr-news/nia-true-eye-technology',
    icons: [newsIconPBS]
  },
  {
    newsHeader: 'news.newsHeader3',
    newsDate: 'news.newsDate3',
    newsDetail: 'news.newsDetail3',
    newsImage: newsImg3,
    newsLink: 'https://techsauce.co/pr-news/nia-true-eye-technology',
    icons: [newsIconTS]
  },
  {
    newsHeader: 'news.newsHeader2',
    newsDate: 'news.newsDate2',
    newsDetail: 'news.newsDetail2',
    newsImage: newsImg2,
    newsLink: 'https://www.bangkokbiznews.com/lifestyle/926802',
    icons: [newsIconBiz]
  },
  {
    newsHeader: 'news.newsHeader1',
    newsDate: 'news.newsDate1',
    newsDetail: 'news.newsDetail1',
    newsImage: newsImg1,
    newsLink: 'https://www.facebook.com/ChulaUTC/videos/%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%AA%E0%B8%B3%E0%B8%84%E0%B8%B1%E0%B8%8D%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%AA%E0%B8%B8%E0%B8%94%E0%B9%80%E0%B8%A5%E0%B8%A2-%E0%B8%84%E0%B8%B7%E0%B8%AD-utc-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%9C%E0%B8%B9%E0%B9%89%E0%B9%80%E0%B8%8A%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%8A%E0%B8%B2%E0%B8%8D%E0%B9%83%E0%B8%99%E0%B8%AA%E0%B8%B2%E0%B8%82%E0%B8%B2%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%A1%E0%B8%B2%E0%B8%8A%E0%B9%88%E0%B8%A7%E0%B8%A2%E0%B8%A7%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B2%E0%B8%B0%E0%B8%AB%E0%B9%8C/485539909461387/',
    icons: [newsIconUTC]
  }

]

const newsDataComputed = computed(() => {
  return { desktopData: groupIntoPairs(newsDataList), mobileData: newsDataList }
})
onMounted(() => {
  console.log(newsDataComputed.value)
})

const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)'),
  swiper: {},
  newsPageIndex: 1,
  newsPageLength: 1
})

const onSlideInit = (swiper) => {
  compState.newsPageLength = swiper.slides.length
  compState.newsPageIndex = swiper.realIndex
}
const onSlideChange = (swiper) => {
  compState.newsPageIndex = swiper.realIndex
}
const onSwiper = (swiper) => {
  compState.swiper = swiper
}
const onNext = () => {
  compState.swiper.slideNext()
}
const onBack = () => {
  compState.swiper.slidePrev()
}
const groupIntoPairs = (arr) => {
  const result = []
  for (let i = 0; i < arr.length; i += 2) {
    if (i + 1 < arr.length) {
      result.push([arr[i], arr[i + 1]])
    } else {
      result.push([arr[i]])
    }
  }
  return result
}
</script>
